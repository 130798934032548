export default defineNuxtRouteMiddleware(async (to) => {
  // We don't want to run the middleware on the server or on initial client hydration
  if (process.server) return
  const nuxtApp = useNuxtApp()
  if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered)
    return

  try {
    const response = await fetch('/api/auth/user')
    const userData = await response.json()
    if (userData.status === 401) {
      if (to.path !== '/dashboard/login') {
        return navigateTo('/dashboard/login')
      }
    }
  } catch (error) {
    console.error('Error in auth middleware:', error)
    return navigateTo('/dashboard/login')
  }
})
